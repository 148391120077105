import {
  CREATE_COLLECTION,
  FIND_COLLECTION, GET_COLLECTIONS_FOR_ALL, GET_COLLECTIONS_LIST,
  SET_COLLECTION_ERROR, SWITCH_COLLECTION_STATE,
  UPDATE_COLLECTION
} from "../store/types";
import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";

export default {
  data(){
    return {
      categoriesForStore: [],
      categoriesForStoreMeta: {}
    }
  },
  methods: {
    deleteCategory(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/manager/collection/${id}`)
        .then(response=>{
          this.hideBusy();
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_COLLECTION_ERROR);
          reject(response);
        })
      })
    },
    createCategory(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(CREATE_COLLECTION, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    updateCategory(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(UPDATE_COLLECTION, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    findCategory(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(FIND_COLLECTION, id)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getCategories(options = {page: 1, title: ''}, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        console.log(GET_COLLECTIONS_LIST);

        this.$store.dispatch(GET_COLLECTIONS_LIST, options)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    switchCategoryState(id, params){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(SWITCH_COLLECTION_STATE, {id, params})
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getCategoriesForStore(title = false, page = 1, busy = false){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post('/manager/collection/list-for-store', {title, page})
        .then(response=>{
          let { data, meta } = response.data;
          this.categoriesForStore = data;
          this.categoriesForStoreMeta = meta;
          this.$store.commit(SET_COLLECTION_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_COLLECTION_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
    getCollectionsForAll(busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_COLLECTIONS_FOR_ALL)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    }
  }
}
